import React from "react"
import Container from "../components/container"
import { injectIntl, FormattedHTMLMessage } from "gatsby-plugin-intl"

const BlockDocteure = ({ intl, alignement = "center" }) => {
  const alignementTitle = alignement === "left" ? "text-left" : "text-center"
  const alignementClass =
    alignement === "left" ? "justify-start" : "justify-center"
  return (
    <Container>
      <div className="block-horaires mt-4 mb-16">
        <h2 className={`text-2xl mt-16 mb-4 ${alignementTitle}`}>
          {intl.formatMessage({ id: "blockdocteure.title" })}
        </h2>
        <div className={`md:flex mb-12 ${alignementClass}`}>
          <div className="flex-col md:w-1/2">
            <div className="mb-4">
              <h3 className="mb-3 uppercase">
                {intl.formatMessage({ id: "blockdocteure.subtitle-1" })}
              </h3>
              <section>
                <FormattedHTMLMessage id="blockdocteure.subtitle-1-p" />
              </section>
            </div>
            <div className="mb-4">
              <h3 className="mb-3 uppercase">
                {intl.formatMessage({ id: "blockdocteure.subtitle-2" })}
              </h3>
              <section>
                <FormattedHTMLMessage id="blockdocteure.subtitle-2-p" />
              </section>
            </div>
            <div className="mb-4">
              <h3 className="mb-3 uppercase">
                {intl.formatMessage({ id: "blockdocteure.subtitle-3" })}
              </h3>
              <section>
                <FormattedHTMLMessage id="blockdocteure.subtitle-3-p" />
              </section>
            </div>
            <div className="mb-4">
              <h3 className="mb-3 uppercase">
                {intl.formatMessage({ id: "blockdocteure.subtitle-4" })}
              </h3>
              <section>
                <FormattedHTMLMessage id="blockdocteure.subtitle-4-p" />
              </section>
            </div>
            <div className="mb-4">
              <h3 className="mb-3 uppercase">
                {intl.formatMessage({ id: "blockdocteure.subtitle-5" })}
              </h3>
              <section>
                <FormattedHTMLMessage id="blockdocteure.subtitle-5-p" />
              </section>
            </div>
          </div>
        </div>
        {/* <div className="text-center">
          <div className={`flex ${alignementClass} mb-2`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#156C8B"
              className="w-4 hidden md:block"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            <p className="ml-2">
              <FormattedHTMLMessage id="blockhoraires.p-3" />
              <a href="https://www.rdvmedicaux.com/annuaire/limousin/haute-vienne/87130- chateauneuf-la-foret/medecin-generaliste/r10247-marie-debord">
                {" "}
                {intl.formatMessage({ id: "blockhoraires.en-ligne" })}
              </a>
            </p>
          </div>
          <div className={`flex ${alignementClass}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#156C8B"
              className="w-4 hidden md:block"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            <p className="ml-2">
              {intl.formatMessage({ id: "blockhoraires.p-4" })}
            </p>
          </div>
        </div> */}
      </div>
    </Container>
  )
}

export default injectIntl(BlockDocteure)
