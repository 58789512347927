import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { css } from "@emotion/react"
import { injectIntl, FormattedHTMLMessage } from "gatsby-plugin-intl"

const underline = css`
  text-decoration: underline;
  color: #fff;
`

const HomeHero = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "cabinet_2023_3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      fluid={data.file.childImageSharp.fluid}
      className="py-32 md:py-52"
    >
      <div className="md:mx-12 md:ml-12 p-12 light-black text-white md:w-5/12 text-xl font-title">
        <p className="mb-4">
          {intl.formatMessage({ id: "homehero.cabinet" })}
          <br />
          <a
            css={underline}
            title="25 rue du champ de foire - La Croisille-sur-Briance"
            href="https://www.google.com/maps?ll=45.63186,1.58321&amp;z=17&amp;t=m&amp;hl=fr-FR&amp;gl=FR&amp;mapclient=embed&amp;q=25+Rue+du+Champ+de+Foire+87130+La+Croisille-sur-Briance"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedHTMLMessage id="homehero.adresse" />
          </a>
        </p>
        <p>
          Tel :{" "}
          <a css={underline} title="telephone" href="tel:0972627630">
            {intl.formatMessage({ id: "homehero.telephone" })}
          </a>
        </p>
      </div>
    </BackgroundImage>
  )
}

export default injectIntl(HomeHero)
