import React from "react"
import Seo from "../components/site-metadata"
import Header from "../components/header"
import HomeHero from "../components/home-hero"
import BlockDocteure from "../components/block-docteure"
import BlockInfos from "../components/block-infos"
import Footer from "../components/footer"
import { injectIntl } from "gatsby-plugin-intl"

const Home = ({ intl }) => {
  return (
    <main className="min-h-screen">
      <Seo
        title={intl.formatMessage({ id: "index.title" })}
        description={intl.formatMessage({ id: "index.description" })}
        image={""}
      />
      <Header />

      <HomeHero />
      <BlockDocteure />
      {/* <BlockInfos /> */}

      <Footer />
    </main>
  )
}

export default injectIntl(Home)
