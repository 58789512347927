import React from "react"
import Container from "../components/container"
import { injectIntl, FormattedHTMLMessage } from "gatsby-plugin-intl"

const BlockInfos = ({intl}) => {
  return (
    <div className="blue-gradient text-white text-center justify-center py-12 font-thin font-title">
      <Container>
        <div className="md:flex leading-6">
          <div className="md:w-1/3">
            <h3 className="text- mb-4 uppercase">{intl.formatMessage({ id: "blockinfos.title-1" })}</h3>
            <FormattedHTMLMessage id="blockinfos.p-1" />
          </div>
          <div className="md:w-1/3">
            <h3 className="text- mb-4 uppercase">{intl.formatMessage({ id: "blockinfos.title-2" })}</h3>
            <FormattedHTMLMessage id="blockinfos.p-2" />
          </div>
          <div className="md:w-1/3">
            <h3 className="text- mb-4 uppercase">{intl.formatMessage({ id: "blockinfos.title-2" })}</h3>
            <FormattedHTMLMessage id="blockinfos.p-3" />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default injectIntl(BlockInfos)